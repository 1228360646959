<!--
 * @Author: your name
 * @Date: 2021-04-25 20:14:58
 * @LastEditTime: 2021-05-19 14:59:28
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\addskill\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation>
      <!-- <div class="addclass">
        <van-icon color="#F44C06" size=".38rem" name="add-o" />
        <div>选择新增</div>
      </div> -->
    </navigation>
    <div class="msgclass">请选择所要添加的技能类型</div>
    <div class="semsgcla" @click="toskillselect">
      <input
        readonly="readonly"
        placeholder="请输入你要搜索的技能"
        type="text"
      />
      <van-icon color="#FF4800" size=".38rem" name="search" />
    </div>
    <div>
      <van-tree-select
        height="55vw"
        :items="allmsg"
        :main-active-index.sync="active"
        @click-nav="clicknav"
      >
        <template #content>
          <div class="jirigc">
            <div
              @click="sskillmeth(item, index)"
              v-for="(item, index) in allmsg2"
              v-bind:key="index"
            >
              {{ item.position_name }}
            </div>
          </div>
        </template>
      </van-tree-select>
    </div>
    <div class="fenxiang">
      <button @click="$router.go(-1)">下一步</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      positionId: "",
      active: 0,
      allmsg: [],
      allmsg2: [], //三级分类
      rightarray: [], //自定义内容
      twoid: "", //第二层id
    };
  },
  created() {
    this.positionId = atob(this.$route.query.positionId);

    this.getskilllist();
  },
  methods: {
    //搜索技能
    toskillselect() {
      this.$router.push({
        path: "/addskillselect",
        query: {
          phone: this.$route.query.phone,
          positionId: this.$route.query.positionId,
          position_name: this.$route.query.position_name,
        },
      });
    },
    //点击技能名称
    sskillmeth(item, index) {
      this.$router.push({
        path: "addskill4",
        query: {
          phone: this.$route.query.phone,
          positionId: btoa(item.pid),
          position_name: this.$route.query.position_name,
          index: index,
        },
      });

      // this.setskill(item);
    },
    //添加技能
    setskill(item) {
      this.$http
        .post("/firm/v1/Skillme/userSkill", {
          reqType: "userinfo",
          phone: atob(this.$route.query.phone),
          skill_type:
            this.$route.query.position_name == "工作技能"
              ? "1"
              : this.$route.query.position_name == "生活技能"
              ? "2"
              : this.$route.query.position_name == "综合技能"
              ? "3"
              : "技能获取失败",
          skill_name: item.position_name,
          skill_id: item.positionId,
          mim_id: localStorage.getItem("userId"),
          status: "1",
        })
        .then((res) => {
          this.$toast("添加成功");
          this.$router.go(-2);
        });
    },
    //点击左侧菜单栏
    clicknav(index) {
      //获取二级技能
      this.getskilllist2(this.allmsg[index].positionId);
      this.twoid = this.allmsg[index].positionId;
    },
    //获取技能云二级
    getskilllist2(id) {
      this.$http
        .post("/firm/v1/Skillme/positionList", {
          reqType: "userinfo",
          pid: id,
          name: "",
        })
        .then((res) => {
          this.allmsg2 = JSON.parse(res.data).data;
        });
    },
    //获取技能云列表
    getskilllist() {
      this.$http
        .post("/firm/v1/Skillme/positionList", {
          reqType: "userinfo",
          pid: this.positionId,
          name: "",
        })
        .then((res) => {
          this.allmsg = JSON.parse(res.data).data;
          this.allmsg.forEach((res) => {
            res.text = res.position_name;
          });
          this.clicknav(0);
          this.getskilllist2(this.allmsg[0].positionId);
        });
    },
  },
};
</script>
<style scoped>
/*在768 和991 像素之间的屏幕里，小屏幕，主要是PAD*/
@media only screen and (min-height: 800px) {
  .allmsgclass >>> .van-tree-select {
    height: 11.5rem !important;
  }
}
@media only screen and (max-height: 800px) {
  .allmsgclass >>> .van-tree-select {
    height: 8.5rem !important;
  }
}
.fenxiang {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  padding: 0.29rem 0;
  background-color: #ffffff;
  border-top-color: #dfdfdf;
  border-top-style: solid;
  border-top-width: 1px;
}
.fenxiang button {
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  border: none;
  padding: 0.28rem 0;
  text-align: center;
  width: 90%;
  margin-left: 5%;
}
.allmsgclass >>> .van-tree-select__nav-item {
  padding: 0.44rem 0.42rem;
}
.jirigc {
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #686868;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
}
.jirigc > div {
  width: 44%;
  padding: 0.44rem 0;
  padding-left: 4%;
  text-align: left;
  font-size: 0.24rem;
}
.allmsgclass >>> .van-sidebar-item--select::before {
  background-color: #ffffff;
}
.semsgcla {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.29rem 0.48rem;
  padding: 0.11rem 0.32rem;
  border: 1px solid #ff4800;
  border-radius: 0.06rem;
}
.semsgcla input {
  border: none;
  font-size: 0.28rem;
  line-height: 0.48rem;
}
.msgclass {
  margin-top: 0.47rem;
  margin-left: 0.48rem;
  font-size: 0.42rem;
  line-height: 0.48rem;
  color: #333333;
  font-weight: bold;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}
.addclass > :first-child {
  margin-right: 0.14rem;
}
.addclass {
  display: flex;
  align-items: center;
  font-size: 0.34rem;
  line-height: 0.48rem;
  color: #333333;
}
</style>
